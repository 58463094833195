.Container {
  display: flex;
  flex-direction: column;
  height: inherit;
  background-color: #fff;

  .Filters { 
    width: 100%;
    display: flex;
    gap: 10px;
    background: var(--geek-blue-1, #F0F5FF);
    padding: 16px;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .ListContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    flex: 1;
  }

  .BelowFilter {
    color: #8C8C8C;
    text-align: center;
    padding: 16px;

    .ResultCounter {
      margin-right: 4px;
    }

    .NewRequestLink {
      text-decoration: none;
      color: #1890FF;
      margin-right: 4px;
      cursor: pointer;
    }
    .EmptyDB {
      text-decoration: none;
      color: #1890FF;
      margin-right: 4px;
      cursor: pointer;
    }
  }

  .NoData {
    justify-content: center;
  }

  .List {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 16px;
    gap: 16px;
    box-sizing: border-box;
  }

  .Pagination {
    padding: 16px;
    margin: auto;   
  }
}