.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .Panel {
    margin: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    text-align: center;
    max-width: 256px;
    background: #f7f7f7;
    border-radius: 16px;
    padding: 32px;

    .Divider {
      margin: 0;
    }

    h1 {
      margin: 0;
      font-size: 24px;
    }

    p {
      margin: 0;
    }
  }
}