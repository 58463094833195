.Container {
  display: flex;
  justify-content: center;
  height: 100%;

  .Panel {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    max-width: 256px;
    background-color: #f7f7f7;
    padding: 32px;
    align-items: center;
    background-color: rgba(255, 255, 255);
  
    h1 {
      margin: 0;
      font-size: 24px;
    }
  
    p {
      margin: 0;
    }

    .Error {
      padding: 12px;
      background-color: #ffb8b8;
      border-radius: 4px;
    } 
  }
}