.Layout {
  display: flex;
  flex-direction: column;
  background-image: url('~/public/background-1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .Header {
    display: flex;
    height: 60px;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    .Left {
      flex: 1;
      display: flex;
      .Expander {
        cursor: pointer;
        display: none;
        align-items: center;
        gap: 8px;
        margin: 0 16px;
        @media screen and (max-width: 1200px) {
          display: flex;
        }
      }
    }
    .Center {
      flex: 1;
      h1 {
        font-size: 24px;
        margin: 0;
        line-height: 60px;
        font-weight: 700;
        text-align: center;
        span {
          color: #1890FF;
        }
      }
    }
    .Right {
      flex: 1;
    }
  }
  .Body {
    height: calc(100dvh - 60px);
    position: relative;
  }
}