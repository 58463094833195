.Container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #FAFAFA;
  text-align: center;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}