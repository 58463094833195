.Container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .HomesList {
    width: 740px;
    margin: auto;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .EmptyState {
    flex: 1;
    border-left: 1px solid #f2f2f2;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &.IsLoaded {
    @media screen and (max-width: 1200px) {
      .HomesList {
        display: none;
      }

      .EmptyState {
        display: block;
      }
    }
  }
}